
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  IAccountingInAYearItem,
  IAccountingInAYear,
  IAmount,
} from "@/models/IPropertyAccounting";
import { HousfyButton, HousfyAnchor } from "housfy-ui-lib";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import Money from "@/models/Money";
import { LocaleMessages } from "vue-i18n";
import MyRentalsConfig from "@/services/MyRentalsConfig";
import { RentalMovementsConcepts } from "@/constants/RentalMovementsConcepts";
import InvoicesFlap from "@/components/InvoicesFlap.vue";

@Component({
  components: {
    HousfySvg,
    HousfyButton,
    HousfyAnchor,
    InvoicesFlap,
  },
})
export default class AccountingMonthlyCardsList extends Vue {
  @Prop({ type: Number, required: true })
  accountingYear!: number;

  @Prop({ type: Object, required: true })
  accountingInAYear!: IAccountingInAYear;

  showAllMovements = false;
  initialMonthsToShow = 2;
  selectedItem: IAccountingInAYearItem | null = null;

  get accountingInAYearItemsSortedByMonth(): IAccountingInAYearItem[] {
    return (
      this.accountingInAYear?.items?.sort((a, b) => b.month - a.month) || []
    );
  }

  handleInvoicesButtonClick(item: IAccountingInAYearItem): void {
    if (!item.invoices.length) return;
    if (item.invoices.length === 1) {
      this.openFile(item.invoices[0].viewUrl);
      return;
    }
    this.selectedItem = item;
  }

  unselectItem(): void {
    this.selectedItem = null;
  }

  openFile(fileUrl: string): void {
    window.open(fileUrl);
  }

  getMonthName(month: number, type: "short" | "long"): string {
    const date = new Date();
    date.setMonth(month - 1);
    const options = { month: type };

    return date.toLocaleString(MyRentalsConfig.country(), options);
  }

  getMovementConceptTranslation(
    concept: string,
    month: number
  ): LocaleMessages | string {
    if (concept === "income")
      return `${this.$t("common.rent")} ${this.getMonthName(month, "long")}`;

    const conceptKeyName: string =
      RentalMovementsConcepts[concept as keyof typeof RentalMovementsConcepts];

    return this.$te(conceptKeyName) ? this.$t(conceptKeyName) : concept;
  }

  formatAmount({ amount, currency }: IAmount, withSign = false): string {
    if (isNaN(amount)) return "";
    const amountString = new Money(amount / 100)
      .withOptions({
        withCents: true,
        currency,
      })
      .format(false);
    return amount > 0 && withSign ? `+${amountString}` : amountString;
  }

  getStringDependingOnDate(date: string): string {
    const givenDate = new Date(date);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (givenDate.toDateString() === today.toDateString())
      return "common.today";
    else if (givenDate.toDateString() === yesterday.toDateString())
      return "common.yesterday";

    return `${givenDate.getDate()} ${givenDate.toLocaleString(
      MyRentalsConfig.country(),
      {
        month: "short",
      }
    )}`;
  }
}
